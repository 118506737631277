@font-face {
  font-family: 'AsylbekM04Parsek.kz';
  src: url('../assets/fonts/AsylbekM04Parsekkz.eot');
  src: url('../assets/fonts/AsylbekM04Parsekkz.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/AsylbekM04Parsekkz.woff2') format('woff2'),
      url('../assets/fonts/AsylbekM04Parsekkz.woff') format('woff'),
      url('../assets/fonts/AsylbekM04Parsekkz.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-LightItalic.eot');
    src: local('PFBeauSansPro-LightItalic'),
        url('../assets/fonts/PFBeauSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-LightItalic.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-Bold.eot');
    src: local('PFBeauSansPro-Bold'),
        url('../assets/fonts/PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-Bold.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-Italic.eot');
    src: local('PFBeauSansPro-Italic'),
        url('../assets/fonts/PFBeauSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-Italic.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-Black.eot');
    src: local('PFBeauSansPro-Black'),
        url('../assets/fonts/PFBeauSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-Black.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-SemiBold.eot');
    src: local('PFBeauSansPro-SemiBold'),
        url('../assets/fonts/PFBeauSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-SemiBold.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-Regular.eot');
    src: local('PFBeauSansPro-Regular'),
        url('../assets/fonts/PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-Regular.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-BoldItalic.eot');
    src: local('PFBeauSansPro-BoldItalic'),
        url('../assets/fonts/PFBeauSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-BoldItalic.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('../assets/fonts/PFBeauSansPro-Light.eot');
    src: local('PFBeauSansPro-Light'),
        url('../assets/fonts/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/PFBeauSansPro-Light.woff') format('woff'),
        url('../assets/fonts/PFBeauSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6DRAW_0.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr4TRAW_0.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* devanagari */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5DRAW_0.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6TRAW_0.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5jRAW_0.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6jRAW_0.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6zRAW_0.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVadyB1Wk.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVYNyB1Wk.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVatyB1Wk.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVZNyB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
/* cyrillic-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4ARPQ_m87A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4ARGQ_m87A.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4ARMQ_m87A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4ARCQ_k.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
html,
main,
#app,
body{
    height: 100%;
}
body{
    font-family: 'Noto Sans', Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    color: #000000;
}
:root{
    --color-blue: #0d2275;
    --color-red: #942720;
}
.empty_block{
    width: 100%;
}
.section_top{
    height: 100vh;
    display: flex;
    align-content: space-between;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 100px 0px;
    border-bottom: 5px solid #dcdcdc;
    h1{
        color: var(--color-blue);
        font-weight: 700;
        font-size: 62px;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        font-family: 'PF BeauSans Pro';
    }
    .top_text{
        font-family: 'PF BeauSans Pro';
        font-size: 28px;
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }
    .company_block{
        border: 3px solid #404040;
        margin: 0 auto;
        padding: 20px 30px;
        width: 100%;
        max-width: 450px;
        p{
            color: #404040;
            font-size: 20px;
            margin-bottom: 15px;
        }
        img{
            max-width: 280px;
            height: auto;
        }
    }
}
.logo_link{
    background: url('../assets/yknews.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 180px;
    height: 25px;
    margin-bottom: -3px;
}
.section{
    padding: 100px 0px;
}
a{
    text-decoration: none;
    &:hover,
    &:focus{
        text-decoration: none;
    }
}
.col_contact{
    color: #404040;
    font-family: 'PF BeauSans Pro';
    h4{
        font-size: 26px;
        margin-bottom: 20px;
        font-weight: bold;
    }
    ul{
        padding: 0px;
        margin: 0px;
        font-size: 26px;
        font-weight: normal;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 15px;
            }
            a{
                color: #404040;
            }
            span{
                margin-right: 10px;
            }
            .ico {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                background-size: 150px;
                .ico_location{

                }
            }
        }
    }
}
.ico{
  display: inline-block;
  &.ico_desktop{
    background: url('../assets/ico/icons.svg');
    background-position: 0px 0px;
  }
  &.ico_mobile{
    background: url('../assets/ico/icons.svg');
    background-position: -45px 0px;
  }
  &.ico_location{
    background: url('../assets/ico/contacts.svg');
    background-position: -79px -4px;
  }
  &.ico_mail{
    background: url('../assets/ico/contacts.svg');
    background-position: -115px -4px;
  }
  &.ico_phone{
    background: url('../assets/ico/contacts.svg');
    background-position: -41px -4px;
  }
  &.ico_wht{
    background: url('../assets/ico/contacts.svg');
    background-position: -3px -4px;
  }
  &.ico_pattern{
    background-repeat: no-repeat;
  }
}
.big_name{
    text-align: center;
    font-weight: bold;
    font-size: 72px;
    font-family: 'PF BeauSans Pro';
    color: #404040;
    margin-bottom: 40px;
    a{
        width: 320px;
        height: 60px;
        margin-bottom: -7px;
    }
}
.subtitle{
    text-align: center;
    font-family: 'Noto Sans';
    font-weight: 300;
    font-size: 38px;
    margin-bottom: 90px;
}
.res_img{
    max-width: 100%;
    height: auto;
}
.laptop_image1{
    text-align: center;
    margin-bottom: 140px;
}
.info_1{
    label{
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 50px;
    }
    .info_items{
        padding-left: 50px;
        .item{
            display: flex;
            font-weight: bold;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 60px;
            }
            .name{
                font-size: 24px;
                min-width: 170px;
                font-family: 'PF BeauSans Pro';
            }
            .val{
                .sum{
                    color: var(--color-red);
                    font-family: Open Sans;
                    font-size: 48px;
                    border: 2px dashed #000000;
                    border-radius: 3px;
                    padding: 1px 15px;
                }
            }
        }
    }
}
.section_title{
    color: #404040;
    font-family: 'PF BeauSans Pro';
    font-weight: bold;
    text-align: center;
    font-size: 68px;
    margin-bottom: 50px;
}
.metric_block{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 50px;
    .item{
        padding-left: 40px;
        padding-right: 40px;
      .metrik_name{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 50px;
      }
      .metrik_graph{
        border-radius: 50%;
        background: black;
        width: 250px;
        height: 250px;
      }
    }
  }
  .metric_desc{
    font-style: italic;
    font-size: 24px;
    max-width: 860px;
    margin: 0px auto;
  }
  .red{
      color: red;
  }
  .metrik_list{
    padding: 0px;
    margin: 0px;
    li{
      list-style: none;
      position: relative;
      font-size: 22px;
      min-width: 380px;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      cursor: default;
      &::after{
        content: "";
        position: absolute;
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: black;
        top: 10px;
        left: 0;
      }
      &.li_red::after{
        background: #c63a04;
      }
      &.li_green::after{
        background: #9ac200;
      }
      &.li_orange::after{
        background: #de7d04;
      }
      &.li_yellow::after{
        background: #f2bd0d;
      }
      &.li_blue::after{
        background: #049ac0;
      }
      &.li_purple::after{
        background: #a74aa3;
      }
      &:not(:last-child){
        padding-bottom: 15px;
      }
    }
  }
.section_3{
  .subtitle{
    margin-bottom: 20px;
  }
}
.col_gp_name{
  font-size: 34px;
  font-style: italic;
  color: #404040;
  padding-top: 8px;
  &.col_gp_padding{
    padding-bottom: 20px;
  }
}
.graph3_img{
  max-width: 780px;
}
.col_graph3{
  text-align: center;
}
.section_4_text{
  margin-bottom: 80px;
  p{
    font-weight: 300;
    font-size: 38px;
    a{
      color: #000;
      &:hover{
        opacity: 0.6;
      }
    }
    &:not(:last-child){
      margin-bottom: 30px;
    }
  }
}
.col_top_pd{
  padding-top: 160px;
}
.red_alert{
  background: var(--color-red);
  color: #ffffff;
  font-weight: bold;
  font-size: 42px;
  border-radius: 5px;
  text-align: center;
  padding: 10px 30px;
}
.section_4{
  .red_alert{
    margin-top: 50px;
  }
}
img{
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;  
}
.section_2 {
  padding-bottom: 400px;
  overflow: hidden;
  position: relative;
  &::before{
    content: "";
    background: url('../assets/back1-min.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    position: absolute;
    top: -240px;
    left: 0;
    width: 100%;
    min-height: 1500px;
  }
  &::after{
    content: "";
    background: url('../assets/people-min.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 600px;
    height: 900px;
    right: 400px;
    bottom: 0px;
  }
  .container{
    position: relative;
    z-index: 20;
  }
}
.section_chart{
  padding-top: 50px;
}
.section_4{
  background: url('../assets/back2-min.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.section_4 .container{
  position: relative;
  z-index: 10;
}
.section_4::after{
  content: "";
  background: url('../assets/back3-min.png');
  background-position: bottom left;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  background-size: contain;
}
.text_5{
  font-weight: 300;
  font-size: 38px;
}
.mobile_bn{
  display: flex;
  justify-content: center;
  margin-top: 60px;
  &.mobile_bn_article{
    .item{
      padding: 0 40px;
    }
  }
  &.mobile_bn_last{
    margin-top: -100px;
  }
  .item{
    padding: 0 20px;
    width: 50%;
    &.item_right{
      text-align: right;
    }
    &.item_left{

    }
    &.item_left_big{
      width: 80%;
      img{
        max-width: 550px;
      }
    }
    img{
      max-width: 350px;
    }
    .item_wrap{
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #212529;
      align-content: center;
      &.t_r{
        justify-content: flex-end;
      }
      h5{
        width: 100%;
        font-weight: 300;
        font-size: 38px;
        margin-bottom: 15px;
      }
      .price{
        width: 100%;
        color: var(--color-red);
        font-size: 46px;
        line-height: 54px;
        font-family: 'AsylbekM04Parsek.kz';
      }
      .price_old{
        width: 100%;
        font-size: 32px;
        line-height: 40px;
        span{
          text-decoration: line-through;
        }
      }
      .price_sum{
        width: 100%;
        font-size: 32px;
        font-style: italic;
        line-height: 40px;
      }
    }
  }
}
.section_bg{
  background: url('../assets/back4-min.png');
  background-repeat: repeat-y;
  background-position: top;
}
.pc_bn{
  display: flex;
  justify-content: center;
  margin-top: 70px;
  .item{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    &.item_lf{
      width: 80%;
    }
    &.item_rg{
      width: 20%;
    }
    .rg_wrap{
      margin-left: -160px;
      &.rg_top{
        padding-bottom: 150px;
        margin-top: -140px;
      }
    }
    .price_old{
      width: 100%;
      font-size: 32px;
      line-height: 40px;
      span{
        text-decoration: line-through;
      }
    }
    .price{
      width: 100%;
      color: var(--color-red);
      font-size: 46px;
      line-height: 54px;
      font-family: AsylbekM04Parsek\.kz;
    }
    .price_sum{
      width: 100%;
      font-size: 32px;
      font-style: italic;
      line-height: 40px;
    }
  }
}
.text_lf_pd{
  padding-left: 255px;
}
.one_banner{
  padding-left: 100px;
  margin-top: -100px;
  img{
    max-width: 600px;
  }
}
.dop_label{
  font-weight: 700;
  color: #000;
  font-size: 32px;
  margin-bottom: 15px;
}
.dop_text{
  font-size: 28px;
}
.section_9{
  padding-top: 0px;
  padding-bottom: 50px;
}
.app_link{
  color: var(--color-red)!important;
  cursor: pointer;
}
.yandex_inform{
  img{
    
  }
}
.yandex_label{
  margin-top: -30px;
}
@media only screen and (min-width: 994px) {
  .mb_an{
    display: none;
  }
  .pc_hide,
  .text_sep{
    display: none;
  }
}
@media only screen and (max-width: 993px) {
  .yandex_label{
    margin-top: -10px;
    margin-bottom: 30px;
  }
  .mobile_title{
    margin-bottom: -30px;
    margin-top: 40px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }
  .mob_title_2{
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: -40px;
  }
  .section_title_m_r{
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #000000;
  }
  .mobile_hide{
    display: none;
  }
  .mobile_bn .item{
    padding: 0px!important;
  }
  .mobile_bn_article{
    flex-direction: column-reverse;
  }
  .mobile_bn_article .item_wrap.t_r{
    margin: 15px 0px;
  }
  .section_6{
    padding-top: 0px!important;
  }
  .section_8{
    background: #e8e8e8;
    padding-top: 0px!important;
  }
  .section_8 .mobile_bn_article{
    margin-top: 10px;
  }
  .section_9,
  .section_8 .text_5{
    display: none;
  }
  .one_banner img,
  .mobile_bn .item.item_left_big img{
    max-width: 100%;
  }
  .mobile_bn .item.item_left_big{
    width: 100%!important;
  }
  .one_banner{
    padding-left: 0px;
    margin-top: 0px;
  }
  .pc_an{
    display: none;
  }
  .section_top{
    padding: 0px 15px;
    align-content: center;
  }
  .section_top h1{
    font-size: 32px;
    margin-bottom: 50px;
  }
  .section_top .top_text{
    font-size: 20px;
    margin-bottom: 50px;
  }
  .section_top .company_block p{
    font-size: 16px;
  }
  .section_top .company_block{
    max-width: 350px;
    padding: 15px;
  }
  .section{
    padding: 50px 0px;
  }
  .col_contact:not(:last-child){
    margin-bottom: 40px;
  }
  .col_contact ul{
    font-size: 20px;
  }
  .col_contact h4{
    font-size: 22px;
  }
  .big_name{
    font-size: 22px;
    margin-bottom: 20px;
  }
  .subtitle{
    font-size: 22px;
    margin-bottom: 40px;
    text-align: left;
  }
  .big_name a{
    width: 120px;
    height: 30px;
  }
  .laptop_image1{
    margin-bottom: 40px;
  }
  .info_1 .info_items{
    padding-left: 0px;
  }
  .info_1 .info_items .item:not(:last-child){
    margin-bottom: 40px;
  }
  .info_1 .info_items .item .name{
    font-size: 20px;
    min-width: 100px;
  }
  .info_1 .info_items .item .val .sum{
    font-size: 32px;
    margin-left: 30px;
  }
  .section_title{
    font-size: 32px;
    margin-bottom: 20px;
    text-align: left;
  }
  .metric_block{
    display: block;
  }
  .text_lf_pd{
    padding-left: 0px!important;
  }
  .metric_block .item{
    padding-left: 0px;
    padding-right: 0px;
  }
  .metric_desc{
    font-size: 18px;
  }
  .graph3_img{
    max-width: 100%;
  }
  .col_gp_name{
    font-size: 24px;
  }
  .section_4_text p{
    font-size: 24px;
  }
  .metrik_list li{
    min-width: 100%;
  }
  .metrik_list li:after{
    top: 13px;
  }
  .metric_block{
    margin-bottom: 20px;
  }
  .chart_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .metric_block .item .metrik_name{
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .section_4_text p:not(:last-child){
    margin-bottom: 15px;
  }
  .section_4_text{
    margin-bottom: 40px;
  }
  .col_top_pd{
    padding-top: 0px;
  }
  .section_4{
    background: #e8e8e8;
  }
  .mobile_bn{
    flex-wrap: wrap;
  }
  .mobile_bn .item{
    padding: 0px;
    width: 100%;
  }
  .mobile_bn.mobile_bn_last{
    margin-top: 0px;
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  .mobile_bn .item .item_wrap .price_old{
    font-size: 28px;
  }
  .mobile_bn .item .item_wrap .price_sum{
    font-size: 28px;
  }
  .mobile_bn .item .item_wrap h5{
    font-size: 32px;
    margin-bottom: 5px;
  }
  .mobile_bn .item.item_right{
    text-align: center;
  }
  .item_left{
    text-align: center;
  }
  .mobile_bn .item .item_wrap.t_r{
    justify-content: flex-start;
  }
  .pc_bn{
    margin-top: 20px;
    display: block;
  }
  .pc_bn .item{
    padding: 0px;
    width: 100%!important;
  }
  .pc_bn .item .rg_wrap.rg_top{
    padding-bottom: 30px;
    margin-top: 0px;
    display: none;
  }
  .pc_bn .item .rg_wrap.rg_top + .rg_wrap{
    display: none;
  }
  .section_7{
    background: #e8e8e8;
  }
  .pc_bn .item .rg_wrap{
    margin-left: 0px;
  }
  .pc_bn .item.item_rg{
    margin-top: 15px;
  }
  .section_4 .red_alert{
    margin-top: 20px;
    font-size: 26px;
    padding: 10px 15px;
    text-align: left;
  }
  .shop_text{
    color: #404040;
    font-family: PF BeauSans Pro;
    font-weight: 700;
    max-width: 280px;
    margin: 0 auto;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 15px;
    span{
      color: var(--color-red);
    }
  }
  .section_4 .res_img{
    max-width: 280px;
    margin: 0px auto;
    display: block;
  }
  .text_5{
    font-size: 26px;
  }
  .text_lf_pd{
    padding-left: 121px;
  }
  .dop_label{
    font-size: 22px;
  }
  .dop_text{
    font-size: 18px;
  }
  .info_1 .info_items .item{
    display: block;
    text-align: center;
  }
  .info_1 .info_items .item .name{
    min-width: auto;
    margin-bottom: 10px;
  }
  .info_1 .info_items .item .val .sum{
    margin-left: 0px;
  }
  .info_1 label{
    line-height: 34px;
  }
  .big_name{
    font-size: 30px;
    a{
      width: 140px;
      height: 33px;
    }
  }
}
@media only screen and (min-width: 994px) and (max-width: 1240px) {
  .col_top_pd{
    padding-top: 115px;
  }
  .section_title{
    font-size: 58px;
  }
  .pc_bn .item .rg_wrap{
    margin-left: -140px;
  }
  .pc_bn .item .price_sum{
    font-size: 22px;
  }
  .pc_bn .item .rg_wrap.rg_top{
    padding-bottom: 70px;
    margin-top: -90px;
  }
  .mobile_bn .item .item_wrap .price_sum{
    font-size: 22px;
  }
  .section{
    padding: 80px 0;
  }
  .red_alert{
    font-size: 30px;
    padding: 10px 20px;
  }
  .section_2:after{
    width: 500px;
    right: -50px;
    bottom: -60px;
  }
  .laptop_image1{
    margin-bottom: 240px;
  }
  .big_name{
    font-size: 52px;
  }
  .big_name a{
    width: 240px;
    height: 45px;
  }
  .section_1{
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 760px) and (max-width: 994px) {
  .col_top_pd{
    padding-top: 83px;
  }
  .mobile_bn .item .item_wrap{
    padding-left: 30px;
  }
  .mobile_bn .item .item_wrap.t_r{
    padding-left: 0px;
    margin-left: -50px;
  }
  .mobile_bn .item .item_wrap h5{
    font-size: 28px;
  }
  .mobile_bn .item .item_wrap .price_sum{
    font-size: 22px;
  }
  .pc_bn .item .rg_wrap{
    margin-left: -100px;
    margin-right: -40px;
  }
  .mobile_bn .item .item_wrap .price_sum,
  .mobile_bn .item .item_wrap .price_old,
  .pc_bn .item .price_sum,
  .pc_bn .item .price_old{
    font-size: 22px;
  }
  .pc_bn .item .rg_wrap.rg_top{
    padding-bottom: 30px;
    margin-top: -40px;
  }
  .mobile_bn .item .item_wrap h5{
    font-size: 22px;
  }
  .mobile_bn .item img,
  .one_banner img,
  .mobile_bn .item.item_left_big img{
    max-width: 100%;
  }
  .one_banner{
    padding-left: 0px;
    margin-top: 0px;
  }
  .mobile_bn.mobile_bn_last{
    margin-top: -30px;
  }
  .section_2:after{
    width: 400px;
    right: -140px;
    bottom: -140px;
  }
  .metric_block{
    flex-wrap: wrap;
  }
  .metric_block .item{
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  .chart_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}